import React, { useState } from "react"
import { navigate } from "gatsby"

export default function IndexPage() {
  const [form, setForm] = useState({
    name: "",
    email: "",
    title: "",
    mcvAward: false,
    agencyAward: false,
    mobile: "",
    officePhone: "+44 (0)121 212 0121",
    discord: "",
    termsSwitch: "",
  })

  const handleChange = (event) => {
    setForm({
      ...form,
      [event.target.name]: event.target.value,
    })
  }

  const handleSwitch = (event) => {
    setForm({
      ...form,
      [event.target.name]: event.target.checked,
    })
  }

  const handleRadios = (event) => {
    setForm({
      ...form,
      [event.target.name]: event.target.value,
    })
  }

  const submitForm = (event) => {
    event.preventDefault()
    if (form.termsSwitch !== "") {
      navigate("/email-signature", { state: form })
    } else {
      document.querySelector("#radioError").classList.remove("hidden")
    }
  }

  return (
    <div className="bg-gradient-to-b from-black to-slate py-24 min-h-screen text-white">
      <form
        className="max-w-xl mx-auto flex flex-col gap-4"
        method="post"
        action="#"
        onSubmit={submitForm}
      >
        <h2 className="mb-4 uppercase text-3xl">Email Signature Generator</h2>
        <div className="flex flex-col items-start gap-2">
          <label htmlFor="name">Employee Name*</label>
          <input
            type="text"
            name="name"
            className="input-text"
            placeholder="Eg. Neil Roddis"
            value={form.name}
            required
            onChange={handleChange}
          />
        </div>
        <div className="flex flex-col items-start gap-2">
          <label htmlFor="email">Employee Email*</label>
          <input
            type="email"
            name="email"
            className="input-text"
            placeholder="Eg. neil@fluidesign.co.uk"
            value={form.email}
            required
            onChange={handleChange}
          />
        </div>
        <div className="flex flex-col items-start gap-2">
          <label htmlFor="title">Employee Title*</label>
          <input
            type="text"
            name="title"
            className="input-text"
            placeholder="Eg. Designer"
            value={form.title}
            required
            onChange={handleChange}
          />
        </div>
        <div className="flex flex-col items-start gap-2">
          <label htmlFor="mcvAward">MCV 2022 Award Logo</label>
          <input
            type="checkbox"
            checked={form.mcvAward}
            name="mcvAward"
            onChange={handleSwitch}
          />
        </div>
        <div className="flex flex-col items-start gap-2">
          <label htmlFor="agencyAward">Global Entertainment Awards Logo</label>
          <input
            type="checkbox"
            checked={form.agencyAward}
            name="agencyAward"
            onChange={handleSwitch}
          />
        </div>
        <div className="flex flex-col items-start gap-2">
          <label htmlFor="mobile">Mobile</label>
          <input
            type="text"
            name="mobile"
            className="input-text"
            placeholder="+44 (0) 7725 306129"
            value={form.mobile}
            onChange={handleChange}
          />
        </div>
        <div className="flex flex-col items-start gap-2">
          <label htmlFor="officePhone">Office Phone</label>
          <input
            type="text"
            name="officePhone"
            className="input-text"
            placeholder="+44 (0)121 212 0121"
            value={form.officePhone}
            onChange={handleChange}
          />
        </div>
        <div className="flex flex-col items-start gap-2">
          <label htmlFor="discord">Discord</label>
          <input
            type="text"
            name="discord"
            className="input-text"
            placeholder="Eg. OliverHeward#8023"
            value={form.discord}
            onChange={handleChange}
          />
        </div>

        <div className="flex flex-col items-start gap-2">
          <p>
            Please select either "Fluid Graphic Design Ltd" or "Fluid Digital
            Media Ltd" to be written into the terms of the email signature.
            <sup>*</sup>
          </p>
          <p className="error text-red hidden" id="radioError">
            This field is required
          </p>
          <div className="flex flex-row flex-nowrap gap-2">
            <input
              type="radio"
              value="Fluid Graphic Design Ltd"
              checked={form.termsSwitch === "Fluid Graphic Design Ltd"}
              name="termsSwitch"
              onChange={handleRadios}
            />
            <label>Fluid Graphic Design Ltd</label>
          </div>
          <div className="flex flex-row flex-nowrap gap-2">
            <input
              type="radio"
              value="Fluid Digital Media Ltd"
              name="termsSwitch"
              checked={form.termsSwitch === "Fluid Digital Media Ltd"}
              onChange={handleRadios}
            />
            <label>Fluid Digital Media Ltd</label>
          </div>
        </div>

        <div className="">
          <input
            type="submit"
            value="Get Signature"
            className="py-2 px-4 bg-white text-slate rounded cursor-pointer hover:bg-orange hover:text-slate transition-colors"
          />
        </div>
      </form>
    </div>
  )
}
